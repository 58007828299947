import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Image,
  Form,
  FormGroup,
  Spinner,
} from "react-bootstrap";
import "../styles/Default_style.css";
import "../styles/Kontak_style.css";
import { handleTextInput } from "../logic/FormInput_Logic";
import { sendContactMail } from "../logic/Contact_logic";

class Kontak extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      message: "",
      error: [false, false, false],
      loading: false,
    };
  }

  submit = async (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    let isValid = this.triggerValidation();
    let success = false;
    if (isValid) {
      let data = {
        name: this.state.name,
        email: this.state.email,
        message: this.state.message,
        loading: false,
      };
      let resp = await sendContactMail(data);
      success = resp[0] && resp[1].status === 200;
      this.props.showModal(success);
    }
    this.setState({
      loading: false,
    });
  };
  triggerValidation = () => {
    const width = window.innerWidth 
              || document.documentElement.clientWidth
              || document.body.clientWidth;
    let error = [false, false, false];
    if (this.state.name === "") error[0] = true;
    if (width < 992)
      {
        if(this.state.email === "") error[1] = [true,"E-mail tidak boleh kosong."]
        else if (!(this.state.email).match(/^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,6})*$/)) error[1] = [true,"E-mail harus memiliki format yang benar"]
      }
    else if (width > 992)
      {
        if(this.state.email === "") error[1] = [true,"E-mail tidak boleh kosong."]
        else if (!(this.state.email).match(/^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,6})*$/)) error[1] = [true,"E-mail harus memiliki format yang benar, contoh: ptindotranssejahtera@gmail.com"]
      }
    if (this.state.message === "") error[2] = true;
    this.setState({
      error: error,
    });
    return error.every((v) => v === false);
  };

  handleChange = (event) => {
    let val = handleTextInput(event.target.name, event);
    this.setState({
      [event.target.name]: val,
    });
  };

  

  render() {
    return (
      <>
        <div
          id="page_kontak"
          style={{
            backgroundImage: "url('./assets/Parkiran/Parkiran 3x.jpg')",
          }}
        >
          <div className="kontak_content">
            <div className="center_align">
                <h1 className="judul">KONTAK KAMI</h1>
                <Form onSubmit={this.submit}>
                    <FormGroup as={Row} className="justify-content-md-center">
                        <Col xs lg="5" className="nama_kontak">
                            <Form.Control
                                className="input_style"
                                id="Name"
                                placeholder="Name"
                                aria-label="Name"
                                aria-describedby="basic-addon1"
                                type="text"
                                name="name"
                                value={this.state.name}
                                onChange={this.handleChange}
                                isInvalid={this.state.error[0]}
                            />
                            <Image
                                src="assets/icon contact - name.svg"
                                className="icon"
                            />
                            <Form.Control.Feedback type="invalid" className="validity_name">
                                Nama tidak boleh kosong
                            </Form.Control.Feedback>
                        </Col>
                        <Col xs lg="5" className="nama_email">
                            <Form.Control
                                className="input_style"
                                id="Email"
                                placeholder="E-mail"
                                aria-label="E-mail"
                                aria-describedby="basic-addon1"
                                type="text"
                                name="email"
                                value={this.state.email}
                                onChange={this.handleChange}
                                isInvalid={this.state.error[1][0]}
                            />
                            <Image
                                src="assets/icon contact - mail.svg"
                                className="icon"
                            />
                            <Form.Control.Feedback type="invalid" className="validity_name">{this.state.error[1] ? this.state.error[1][1] : ""}</Form.Control.Feedback>
                        </Col>
                    </FormGroup>
                    <FormGroup as={Row} className="justify-content-md-center">
                        <Col lg="10" className="nama_textarea">
                            <Form.Control
                                id="text_area"
                                placeholder="Message"
                                aria-label="Texarea"
                                aria-describedby="basic-addon1"
                                size="lg"
                                as="textarea"
                                name="message"
                                value={this.state.message}
                                onChange={this.handleChange}
                                isInvalid={this.state.error[2]}
                            />
                            <Form.Control.Feedback
                                id="msg_error"
                                type="invalid"
                                className="validity_name"
                            >
                                Keterangan tidak boleh kosong
                            </Form.Control.Feedback>
                        </Col>
                    </FormGroup>
                    <FormGroup as={Row} className="justify-content-md-center button_container">
                            <Button id="input_button" type="submit">
                            {this.state.loading ? (
                                <Spinner variant="secondary" animation="border" />
                            ) : (
                                "Kirim    "
                            )}
                            </Button>
                    </FormGroup>
                </Form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Kontak;
