export function handleTextInput(name, event){
    let res = event.target.value;
    let regex_text = /^[a-zA-Z\s]+$/;
    let regex_email = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/;
    let regex_lc = /(\b[a-z](?!\s))/g;
    let status = false;

    switch (name) {
      case "email":
        res = res.replace(/\s+/g, " ");
        status = event.target.value.match(regex_email) ? true : false;
        break;
      case "name":
        res = res.replace(regex_lc, function(x) {
          return x.toUpperCase();
        });
        res = res.replace(/\s\s+/g, " ");
        status = event.target.value.match(regex_text) ? true : false;
        break;
      case "city":
        res = res.replace(regex_lc, function(x) {
          return x.toUpperCase();
        });
        res = res.replace(/\s\s+/g, " ");
        status = event.target.value.match(regex_text) ? true : false;
        break;
      case "username":
        res = res.replace(/\s/g,"");
        break;
      case "no_polisi":
        res = res.replace(/[^a-zA-Z0-9]/g,"");
        res = res.replace(/\s/,"");
        break;
      default:
        res = res.replace(regex_lc, function(x) {
          return x.toUpperCase();
        });
        res = res.replace(/\s\s+/g, " ");
        status = true;
        break;
    }
    return res;
  };