import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import NavigationBar from "../components/NavigationBar_Com";
import Beranda from "../components/Beranda_Com";
import VisiMisi from "../components/VisiMisi_Com";
import Foot from "../components/Footer_Com";
import Kontak from "../components/Kontak_Com";
import BackToTop from "react-easy-back-to-top";
import Fasilitas from "../components/Fasilitas_Com_Both";
import Penghargaan from "../components/Penghargaan_Com_both";
import '../styles/Default_style.css';
import "../styles/Home_style.css";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventData: null,
      open: true,
      totalMember: 0,
      pastEvent: null,
      upcomingEvent: null,
      sponsor: null,
      windowswidth: window.innerwidth,
      showModal: false,
      modalStatus: false
    };
  }

  getWidth() {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );
  }

  state = {
    didViewCountUp: false
  };

  onVisibilityChange = isVisible => {
    if (isVisible) {
      this.setState({ didViewCountUp: true });
    }
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  updateWindowDimensions = () => {
    this.setState({ windowswidth: window.innerwidth });
    console.log("test", window.innerwidth);
  }

  showModal = (status) => {
    this.setState({
      showModal: true,
      modalStatus: status
    })
  }

  handleClose = () => {
    this.setState({
      showModal: false
    })
  }

  reload = () => {
    window.location.reload();
  }

  render() {
    return (
      <div windowswidth={this.state.windowswidth} style={{ width: "100%" }}>
          <BackToTop
            icon="fas fa-arrow-up"
            backgroundColor="#C1272D"
            position={{ bottom: "0%", right: "0%" }}
            hover={{ backgroundColor: "#C1272D", opacity: "0.95" }}
            transition="all 0.5s"
            margin="32px"
          />
        <Modal show={this.state.showModal} onHide={this.state.modalStatus ? this.reload : this.handleClose} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>{this.state.modalStatus ? "Sukses" : "Gagal"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.modalStatus ? 
            "Pesan anda telah berhasil dikirim." : 
            "Pesan anda gagal dikirim"}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.state.modalStatus ? this.reload : this.handleClose}>
              Confirm
          </Button>
          </Modal.Footer>
        </Modal>
        <NavigationBar></NavigationBar>
        <Beranda></Beranda>
        <VisiMisi></VisiMisi>
        <Fasilitas></Fasilitas>
        <Penghargaan></Penghargaan>
        {/* {this.state.windowswidth < 767 ? <PenghargaanM /> : <Pengharg />} */}
        <Kontak showModal={this.showModal}></Kontak>
        <Foot></Foot>

      </div>
    );
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
}

export default Home;