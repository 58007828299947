import axios from "axios";
const BASE_URL = "https://morning-bastion-23858.herokuapp.com/";
const CONTACT = "contactUs";

export const sendContactMail = async (data) => {
    let responseData = null;
    await axios({
        method: "post",
        url: CONTACT,
        baseURL: BASE_URL,
        headers: {
            "Content-Type": " application/json"
        },
        data: {
            "name": data.name,
            "email": data.email,
            "message": data.message
        }
    }).then((response) => {
        responseData = response.data
    }).catch(error => {
        console.log("error", error);
        return [false, responseData];
    });
    return [true, responseData];
};