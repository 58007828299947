import React from "react";
import {Image, Row, Col} from "react-bootstrap";
import "../styles/Default_style.css";
import "../styles/Footer_style.css";

function Footer(){
        return(
            <>
                <Row className="footer_container">
                    <Col id="logo_footer_container">
                        <Image id="logo_footer" src="../assets/LogoPertamina/Logo_Pertamina 3x.jpg"></Image>
                    </Col>
                    <Col className="col_footer_container">
                        <div id="isi_footer_container">
                            <div id="isi_footer_container_1">
                                <p className="judul_footer"><strong>Samarinda Head Office</strong></p>
                                <p>Jl. Panglima Batur, Kompleks Citra Niaga</p>
                                <p>Blok B-12 Samarinda, Kalimantan Timur 75112</p>
                                <p>0541 743717</p>
                                <p>indotrans.sejahtera@gmail.com</p>
                            </div>
                            <div id="isi_footer_container_2">
                                <p className="judul_footer"><strong>Pangkalanbun Branch Office</strong></p>
                                <p>Jl. Iskandar no 23, RT. 008 Kel. Madurejo,</p>
                                <p>Pangkalanbun, Kalimantan Tengah 74112</p>
                                <p>085249672303</p>
                                <p>info@ptindotrans.com</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </>
        );
}
export default Footer;