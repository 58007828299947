import React, { Component } from "react";
import { Row, Col, Image} from "react-bootstrap";
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
import Fade from "react-reveal";
import "../styles/Default_style.css";
import "../styles/Fasilitas_style.css";

const asset1 = {
    index: 0,
    picture: "./assets/Kapal/Boat 2x.jpg",
    description: "KAPAL",
    subDescription: "SPOB. SCC STAR 6"
  }
  const asset2 = {
    index: 1,
    picture: "./assets/Kapal/Sungai_Kais.jpeg",
    description:"KAPAL",
    subDescription: "SPOB. SUNGAI KAIS 01"
  }
  const asset3 = {
    index: 2,
    picture: "./assets/Kapal/Star_Hill.jpeg",
    description: "KAPAL",
    subDescription: "SPOB. STAR HILL"
  }
  const asset4 = {
    index: 3,
    picture: "./assets/Truck/Truck_Izuzu.jpeg",
    description: "TRUCK TANKY",
    subDescription: "TANKY UKURAN 10KL"
  }
  const asset5 = {
    index: 4,
    picture: "./assets/Truck/Truck 3x.jpg",
    description: "TRUCK TANKY",
    subDescription: "TANKY UKURAN 5KL"
  }
  const responsive = {
    0:{
      items: 2
    },
    1024: {
      items: 2
    },
    1500: {
      items : 3
    }
  };
class Fasilitas extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          eventData: [asset1, asset2, asset3, asset4, asset5,],
          activeData: { index: 0, picture: null, description: null },
          currentIndex: 0,
        };
      }
      slideTo = (i) => this.setState({ currentIndex: i });

      onSlideChanged = (e) => this.setState({ currentIndex: e.item });

      slideNext = () => this.setState({ currentIndex: this.state.currentIndex + 1 });

      slidePrev = () => this.setState({ currentIndex: this.state.currentIndex - 1 });
      render() {
        return (
          <>
            <div id="page_fasilitas" style={{backgroundImage:"url('assets/PatternPertamina/pattern 5.png')"}}>
              <Fade duration={3000}>
                <h1 className="judul">FASILITAS ARMADA</h1>
              </Fade>
              <Row style={{position:"relative"}}>
                <Image className="icon_left" src='assets/rightArrow_icn.svg' onClick={() => this.slidePrev()}/>
                <Col md={{ span: 10, offset: 1 }} className="sub_page_fasilitas">
                  <Fade duration={3000}>
                      <div id="fasilitas_list">
                        {this.GenerateArmada()}
                      </div>
                  </Fade>
                </Col>
                <Image className="icon_right" src='assets/leftArrow_icn.svg' onClick={() => this.slideNext()}/>
              </Row>
            </div>
          </>
        );
      }
      GenerateArmada(){
        var cards=[];
        const { currentIndex} = this.state;
        if(this.state.eventData){ 
            this.state.eventData.map((data) =>{

              cards.push(
                    <div id={data.index} className="pilihan_fasilitas">
                      <Fade duration={3000}>
                            <div className="content">
                              <Image
                                src={data.picture}
                              />
                              <div id="description_fasilitas">
                                  <Fade right duration={1000} delay={500}>
                                    <p>{data.description}</p>
                                  </Fade>
                                  <Fade right duration={1000} delay={1000}>
                                    <p id="isi_description">{data.subDescription}</p>
                                  </Fade>
                              </div>
                            </div>
                      </Fade>
                    </div>
              );
            });
            let armada = (<AliceCarousel 
                dotsDisabled={true}
                buttonsDisabled={true}
                slideToIndex={currentIndex}
                onSlideChanged={this.onSlideChanged}
                responsive={responsive}
                fadeOutAnimation={true}
                >
                 {cards}
              </AliceCarousel>);
            return armada;
        }
    }
}

export default Fasilitas;