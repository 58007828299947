import React from 'react';
import './App.css';
import Home from "./page/Home"
import 'bootstrap/dist/css/bootstrap.min.css';
function App() {
  return (
    <>
      <Home></Home>
    </>
  );
}

export default App;

