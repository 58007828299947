import React from "react";
import { Navbar, Button, Nav, Image} from "react-bootstrap";
import "../styles/Default_style.css";
import "../styles/Navbar_style.css";
import AnchorLink from 'react-anchor-link-smooth-scroll'

function NavigationBar () {
        return (
            <>                
                <Navbar data-spy="scroll" data-target=".navbar" collapseOnSelect expand="lg" bg="transparent" variant="light">
                    <Nav.Link href="/" className="navbar_logo_container">
                        <Image className="logo_nav" src="./assets/Logo/Logo 3x.png" />
                    </Nav.Link>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ml-auto">
                            <AnchorLink id="navlink" className="activeNav" href="#navlink" >
                                <Button id="navbar_button" style={{color: "white"}} >
                                    Beranda
                                </Button>
                            </AnchorLink>
                            <AnchorLink id="navlink" className="" href="#judul_profile">
                                <Button id="navbar_button">
                                    Profile Perusahaan
                                </Button>
                            </AnchorLink>
                            <AnchorLink id="navlink" className="" href="#page_penghargaan">
                                <Button id="navbar_button">
                                    Penghargaan
                                </Button>
                            </AnchorLink>
                            <AnchorLink id="navlink" className="" href="#page_kontak">
                                <Button id="navbar_button" >
                                    Kontak Kami
                                </Button>
                            </AnchorLink>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </>
        );    
}

export default NavigationBar;