import React, { Component } from "react";
import {Row} from "react-bootstrap";
import Fade from "react-reveal"
import "../styles/Default_style.css";
import "../styles/Penghargaan_style.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const asset1 = {
  index: 0,
  picture: "./assets/Penghargaan/Gold_2018.jpg",
  description: "BEST PERFORMANCE AGENT KALIMANTAN GOLD 2018",
};
const asset2 = {
  index: 1,
  picture: "./assets/Penghargaan/Gold_2019.jpg",
  description: "BEST PERFORMANCE AGENT KALIMANTAN GOLD 2019"
};

class Penghargaan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventData: [asset1, asset2, ],
            activeData: { index: 0, picture: null, description: null },
            open: true,
        };
    }
    GeneratePenghargaan(){
      var cards=[];
      if(this.state.eventData){ 
          this.state.eventData.map((data) =>{
            cards.push(
              <div key={data.index} id={data.index} className="penghargaan_image_container">
                <Fade top duration={1000}>
                  <LazyLoadImage
                    src={data.picture}
                  />
                </Fade>
                <Fade left duration={1000} delay={500}>
                  <div className="description_penghargaan">
                    <p>
                      {data.description}
                    </p>
                  </div>
                </Fade>
              </div>
            );
          });
          let penghargaan = (<Row className="pilihan_penghargaan">
            {cards}
          </Row>);
          return penghargaan;
      }
    }
    render() {
        return (
            <>
              <div id="page_penghargaan">
                <div style={{backgroundImage:"url('assets/PatternPertamina/pattern 2.png')"}}>
                  <Fade duration={3000}>
                    <h1 className="judul">PENGHARGAAN</h1>
                  </Fade>
                  {this.GeneratePenghargaan()}
                </div>
              </div>
          </>
        );
    }

    
    
}

export default Penghargaan;