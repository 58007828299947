import React from "react";
import Fade from "react-reveal/Fade"
import "../styles/Default_style.css";
import "../styles/Beranda_style.css"


function Beranda(){
        return (
            <>
                <div className="page_beranda" style={{backgroundImage:"url('assets/Port/Port 3x.jpg')", zIndex: "-1"}}>
                    {/* <Image id="img1" src="./assets/Port/Port 3x.jpg"></Image> */}
                    <Fade duration={2000} left>
                        <div className = "component_content">
                            <Fade duration={1000} delay={1000} >
                                <h1 className="judul_beranda">PT. INDOTRANS SEJAHTERA</h1>
                            </Fade>
                            <Fade right duration={1000} delay={1000}>
                                <div className="isi_beranda">
                                    <h1><strong>Kami menyediakan kualitas</strong></h1>
                                    <h1><strong>bahan bakar terbaik untuk anda</strong></h1>
                                </div>
                            </Fade>
                        </div>
                    </Fade>
                </div>
            </>
        )
}

export default Beranda;