import React from "react";
import { Image, Row, Col } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import "../styles/Default_style.css";
import "../styles/VisiMisi_style.css";

function VisiMisi() {
  return (
    <>
      <div id="page_profile" style={{backgroundImage:"url('assets/PatternPertamina/pattern 1.png')"}}>
      <Image id="pattern1" src="./assets/PatternPertamina/pattern 1.png"/>
        <div className="center_align">
          <Row className="justify-content-md-center sub-container">
            <Col id="judul_profile" lg="5">
              <Fade duration={1000} left cascade>
                <h1>WE ARE</h1>
                <h1>THE LEADING</h1>
                <h1>COMPANY</h1>
              </Fade>
            </Col>
            <Col md="auto"></Col>
            <Col lg="5">
              <Fade duration={1000} right cascade>
                  <div className="description_container">
                    <p>
                    PT. INDOTRANS SEJAHTERA adalah perusahaan yang bergerak dalam
                    bidang niaga Bahan Bakar Minyak (BBM) yang terdaftar secara
                    resmi sebagai Agen dan Transportir BBM Industri Pertamina.
                    </p>
                    <p>
                    Perusahaan kami memasarkan dan menjual BBM non subsidi untuk
                    sektor industri, transportasi, kontraktor, pertambangan,
                    perkebunan, kapal, dan lain sebagainya. Memiliki pengalaman
                    lebih dari satu dekade sebagai Mobile Bunker Agen / Agen
                    Industri Pertamina, kami berkomitmen dalam menjaga standar
                    mutu produk dan pelayanan pelanggan.
                    </p>
                  </div>
              </Fade>
            </Col>
          </Row>
          <Row id="row_profile" className="justify-content-md-center sub-container">
            <Col lg="5">
              <Fade duration={1000} left cascade>
                  <div className="description_container">
                    <h5>VISI</h5>
                    <p>
                    Menjadi salah satu perusahaan Agen Pertamina yang kompetitif
                    dalam menjual dan mendistribusikan Bahan Bakar Minyak (BBM)
                    Industri, terdepan akan kualitas dan kuantitas dalam setiap
                    produk dan pelayanannya.
                    </p>
                  </div>
              </Fade>
            </Col>
            <Col md="auto"></Col>
            <Col lg="5">
              <Fade duration={1000} right cascade>
                  <div className="description_container">
                    <h5>MISI</h5>
                    <p>
                    Melayani kebutuhan konsumen akan BBM industri khususnya di
                    wilayah operasi kami dengan mempertahankan serta meningkatkan
                    margin penjualan BBM khusunya solar industri (HSD).
                    </p>
                  </div>
              </Fade>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
export default VisiMisi;
